@import '../../../styles/shared';

.wrapper {
  padding: 50px 0;
  font-family: var(--classic-footer-text-font-family);
  color: var(--classic-footer-color);
  background-color: var(--classic-footer-background-color);

  @include mq($from: wide) {
    padding: 80px 0 70px;
  }
}

.upperFooter {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include mq($from: desktop) {
    flex-direction: row;
    gap: 100px;
  }

  @include mq($from: wide) {
    gap: 180px;
  }
}

.primaryNav,
.secondaryNav {
  flex: 1 0;

  @include mq($from: desktop) {
    max-width: 50%;
  }
}

.line {
  height: 1px;
  margin: 30px 0 22px;
  background-color: #fff;

  @include mq($from: desktop) {
    background-color: rgba(255, 255, 255, 0.34);
  }

  @include mq($from: wide) {
    margin: 30px 0;
  }
}

.mobileLine {
  display: block;
  margin-bottom: 30px;

  @include mq($from: desktop) {
    display: none;
  }
}

.lowerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.lowerFooterLeft {
  display: flex;
  align-items: center;
  gap: 30px;
}

.desktopLogo {
  display: none;

  img {
    display: block;
    max-height: var(--classic-footer-logo-max-height);
  }

  @include mq($from: desktop) {
    display: block;
  }
}

.mobileLogo {
  display: flex;
  justify-content: center;

  & + & {
    margin-top: 30px;
  }

  img {
    display: block;
    max-height: var(--classic-footer-logo-max-height);
  }

  @include mq($from: desktop) {
    display: none;
  }
}

.publicNav {
  position: relative;

  @include mq($from: desktop) {
    top: 4px;
  }
}

.socialNetworkNavDesktop {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }
}

.socialNetworkNavMobile {
  margin-top: 30px;

  @include mq($from: desktop) {
    display: none;
  }
}

.copyright {
  margin-top: 20px;
  font-family: var(--classic-footer-text-font-family);
  font-size: var(--classic-footer-copyright-font-size);
  color: var(--classic-footer-copyright-color);

  a {
    color: inherit;
  }

  p + p {
    margin-top: 5px;
  }

  @include mq($from: wide) {
    margin-top: 40px;
  }
}
